import { inject, Pipe, type PipeTransform } from '@angular/core';

import type { Nullable } from '@cosmos/types-common';
import type { FeatureFlags } from '@cosmos/types-feature-flags';

import { FeatureFlagsService } from '../providers';

@Pipe({
  name: 'featureFlagsFilter',
})
export class FeatureFlagsFilterPipe implements PipeTransform {
  private _featureFlagsService = inject(FeatureFlagsService);
  transform<T extends FeatureFlags>(items: Nullable<T[]>): T[] {
    return filterByFeatureFlags(items, this._featureFlagsService);
  }
}

function filterByFeatureFlags<T extends FeatureFlags>(
  items: Nullable<T[]>,
  flagsService = inject(FeatureFlagsService)
): T[] {
  return (items || []).filter((item) => {
    const flags = new Array<string>().concat(item.featureFlags?.matches || []);
    return flags.every((f) => flagsService.isEnabled(f));
  });
}
