import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  signal,
  ViewEncapsulation,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs';

import { CosGlobalMenuComponent } from '@cosmos/components/global-header';
import { useLocalState } from '@cosmos/state';
import type { MenuItem, NavigationItem } from '@cosmos/types-layout';
import { injectDestroyRef } from '@cosmos/util-common';
import {
  CosmosTranslocoService,
  CosmosUtilTranslationsModule,
  LanguageScope,
} from '@cosmos/util-translations';
import {
  assembleDesktopItems,
  assembleMobileItems,
} from '@customer-portal/common/feature-navigation-menu';

import { CustomerPortalHeaderLoaderComponent } from './components/header-loader';
import { HeaderLocalState } from './header.local-state';

@Component({
  selector: 'customer-portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'customer-portal-header',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderLocalState],
  imports: [
    CosmosUtilTranslationsModule,
    CosGlobalMenuComponent,
    CustomerPortalHeaderLoaderComponent,
  ],
})
export class CustomerPortalHeaderComponent implements OnInit {
  readonly navItemsDesktop = signal<NavigationItem[]>([]);
  readonly navItemsMobile = signal<MenuItem[]>([]);

  readonly showDetails = signal(false);

  readonly state = useLocalState(HeaderLocalState, this);
  readonly stateSignal = this.state.asSignal();

  private readonly _destroyRef = injectDestroyRef();

  constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _translocoService: CosmosTranslocoService
  ) {}

  @HostBinding('style.border-color')
  get classList() {
    return this.state.project?.Customer.PrimaryBrandColor;
  }

  ngOnInit(): void {
    this.state
      .asObservable()
      .pipe(
        filter((state) => state.isReady),
        switchMap(() =>
          this._translocoService.ensureScopesAreLoaded$([
            LanguageScope.CustomerPortalCommon,
          ])
        ),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.updateMenuItems();
      });
  }

  updateMenuItems() {
    const state = this.stateSignal();
    const menuOptions = {
      ...state,
      project: state.project!,
      presentation: state.presentation!,
    };
    this.navItemsDesktop.set(assembleDesktopItems(menuOptions));
    this.navItemsMobile.set(assembleMobileItems(menuOptions));
    this._cdRef.detectChanges();
  }
}
