@if (userIsDistributor) {
  @let t = 'customerPortalCommon.feature-header' | cosTranslationCtx;
  <div class="project-host__header print:!hidden">
    @if (previewingPresentation) {
      <span>{{ t('preview-presentation') }}</span>
    }
    @if (routesParam.orderId) {
      <span>{{ t('preview-order') }}</span>
    }
    @if (routesParam.quoteId) {
      <span>{{ t('preview-quote') }}</span>
    }
    @if (routesParam.invoiceId) {
      <span>{{ t('preview-invoice') }}</span>
    }
  </div>
}
@if (vm().showHeader) {
  @if (isLegacyTemplate) {
    <customer-portal-header class="print:!hidden" />
  } @else {
    <customer-portal-new-header
      [landingMode]="vm().landingMode"
      class="print:!hidden"
    />
  }
}
<router-outlet />
<ng-container teleportOutlet="productCarousel" />
@if (vm().showFooter) {
  <customer-portal-footer />
}
