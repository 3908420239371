import { inject, Injectable } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { map, tap } from 'rxjs';

import {
  fromObservable,
  fromSelector,
  LocalState,
  stateBehavior,
  writableProp,
} from '@cosmos/state';
import { CustomerPortalAuthQueries } from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalDistributorQueries,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectDetailsQueries,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';

@Injectable()
export class HeaderLocalState extends LocalState<HeaderLocalState> {
  private _store = inject(Store);

  readonly isProductsPage = fromObservable(
    this._store.select(RouterState.url).pipe(
      map((url) => {
        return url?.endsWith('/products');
      })
    )
  );

  readonly userIsCustomer = fromSelector(
    CustomerPortalAuthQueries.getUserIsCustomer
  );

  readonly project = fromSelector(CustomerPortalProjectQueries.getProject);

  readonly presentation = fromSelector(
    CustomerPortalPresentationQueries.getPresentation
  );

  readonly cartCount = fromSelector(
    CustomerPortalProjectDetailsQueries.getCartCount
  );

  readonly quoteCount = fromSelector(
    CustomerPortalProjectDetailsQueries.getQuoteCount
  );

  readonly orderCount = fromSelector(
    CustomerPortalProjectDetailsQueries.getOrderCount
  );

  readonly invoiceCount = fromSelector(
    CustomerPortalProjectDetailsQueries.getInvoiceCount
  );

  readonly proofCount = fromSelector(
    CustomerPortalProjectDetailsQueries.getProofCount
  );

  readonly presentationExpired = fromSelector(
    CustomerPortalPresentationQueries.getPresentationExpired
  );

  readonly distributor = fromSelector(
    CustomerPortalDistributorQueries.getProfile
  );

  isReady = writableProp(false);

  private _setIsReady = stateBehavior<HeaderLocalState>(this, (state$) => {
    return state$.pipe(
      tap(() => {
        this.isReady =
          this.project != null &&
          this.presentation != null &&
          this.cartCount != null &&
          this.quoteCount != null &&
          this.presentationExpired != null &&
          this.distributor != null;
      })
    );
  });
}
