<div class="customer-portal-header__topbar">
  <div
    class="customer-portal-header__topbar-container"
    [class.customer-portal-header__topbar-container--restricted]="
      !landingMode()
    "
  >
    @if (state.isReady) {
      <div class="customer-portal-header__topbar-info">
        @if (state.project!.Customer.LogoImageUrl) {
          <div class="flex items-center relative">
            <img
              class="customer-portal-header__logo-img"
              [attr.src]="state.project!.Customer.LogoImageUrl"
              [alt]="state.project!.Name"
            />
            @if (logoAsLink()) {
              <a [routerLink]="logoLinkUrl()" class="absolute inset-0"> </a>
            }
          </div>
        }

        @if (!landingMode()) {
          <div
            class="cos-divider cos-divider-horizontal !m-0 first:hidden"
          ></div>
          <div class="flex items-center">
            <h2 class="customer-portal-header__title">
              {{ state.project!.Name }}
            </h2>
          </div>
        }
      </div>
      @if (!landingMode()) {
        <cos-global-menu
          class="self-center ml-auto"
          [navItemsDesktop]="navItemsDesktop()"
          [navItemsMobile]="navItemsMobile()"
        />
      }
    } @else {
      <customer-portal-header-loader
        class="w-full"
        [landingMode]="landingMode()"
      />
    }
  </div>
</div>
@if (state.presentationExpired && state.isReady && state.isProductsPage) {
  <div class="expiration-banner text-header-base flex justify-center">
    This Presentation has expired. Please reach out to
    {{ state.distributor?.Name }} for the most recent product information.
  </div>
}
