import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  input,
  signal,
  ViewEncapsulation,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { filter, switchMap, take } from 'rxjs';

import { CosGlobalMenuComponent } from '@cosmos/components/global-header';
import { useLocalState } from '@cosmos/state';
import type { MenuItem, NavigationItem } from '@cosmos/types-layout';
import { coerceBooleanAttribute } from '@cosmos/util-coercion';
import { injectDestroyRef } from '@cosmos/util-common';
import {
  CosmosTranslocoService,
  CosmosUtilTranslationsModule,
  LanguageScope,
} from '@cosmos/util-translations';
import {
  assembleDesktopItems,
  assembleMobileItems,
} from '@customer-portal/common/feature-navigation-menu';

import { CustomerPortalHeaderLoaderComponent } from './header-loader.component';
import { HeaderLocalState } from './header.local-state';

@Component({
  selector: 'customer-portal-new-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'customer-portal-header',
    '[class.customer-portal-header--ready]': 'state.isReady',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderLocalState],
  imports: [
    RouterLink,
    CosmosUtilTranslationsModule,
    CosGlobalMenuComponent,
    CustomerPortalHeaderLoaderComponent,
  ],
})
export class CustomerPortalNewHeaderComponent implements OnInit {
  readonly navItemsDesktop = signal<NavigationItem[]>([]);
  readonly navItemsMobile = signal<MenuItem[]>([]);

  readonly state = useLocalState(HeaderLocalState, this);
  readonly stateSignal = this.state.asSignal();

  private readonly _destroyRef = injectDestroyRef();
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _translocoService = inject(CosmosTranslocoService);

  readonly landingMode = input(false, { transform: coerceBooleanAttribute });

  readonly logoAsLink = computed(
    () => !this.landingMode() && this.stateSignal().presentation
  );

  readonly logoLinkUrl = computed(() => {
    const { project, presentation } = this.stateSignal();
    return ['/projects', project?.Id, 'presentations', presentation?.Id];
  });

  ngOnInit(): void {
    this.state
      .asObservable()
      .pipe(
        filter((state) => state.isReady),
        take(1),
        switchMap(() =>
          this._translocoService.ensureScopesAreLoaded$([
            LanguageScope.CustomerPortalCommon,
          ])
        ),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.updateMenuItems();
      });
  }

  updateMenuItems() {
    const state = this.stateSignal();
    const menuOptions = {
      ...state,
      project: state.project!,
      presentation: state.presentation!,
    };
    this.navItemsDesktop.set(
      // Remove icons and badges from desktop items
      assembleDesktopItems(menuOptions).map((item) => ({
        ...item,
        icon: undefined,
        badge: undefined,
      }))
    );
    this.navItemsMobile.set(
      // Remove badges from mobile items
      assembleMobileItems(menuOptions).map((item) => ({
        ...item,
        badge: undefined,
      }))
    );
    this._cdRef.detectChanges();
  }
}
