import type { MenuItem, NavigationItem } from '@cosmos/types-layout';
import {
  PresentationStatus,
  type Presentation,
} from '@esp/presentations/types';
import type { Project } from '@esp/projects/types';

const keyPrefix = 'customerPortalCommon.feature-header';

export const enum MenuItemId {
  ProductsMenuItem = 'presentation-products-menu',

  CartMenuItem = 'presentation-cart-menu',

  QuotesMenuItem = 'presentation-quotes-menu',

  OrdersMenuItem = 'orders-menu',

  ProofsMenuItem = 'proofs-menu',

  InvoicesMenuItem = 'invoices-menu',
}

export interface NavigationMenuOptions {
  cartCount: number;
  quoteCount: number;
  orderCount: number;
  invoiceCount: number;
  proofCount: number;

  userIsCustomer: boolean;
  presentationExpired: boolean;
  project: Project;
  presentation: Presentation;
}

export function assembleDesktopItems<T extends NavigationMenuOptions>(
  options: T
): NavigationItem[] {
  const {
    userIsCustomer,
    cartCount,
    quoteCount,
    orderCount,
    invoiceCount,
    proofCount,
  } = options;

  const project = options.project!;
  const presentation = options.presentation!;
  const presentationExpired = options.presentationExpired;

  return [
    {
      id: MenuItemId.ProductsMenuItem,
      title: `${keyPrefix}.products-label`,
      type: 'item',
      icon: 'fa fa-boxes',
      url: [
        `/projects/${project.Id}/presentations/${presentation.Id}/products`,
      ],
      hidden:
        userIsCustomer && presentation.Status === PresentationStatus.PreShare,
    },
    userIsCustomer && {
      id: MenuItemId.CartMenuItem,
      title: `${keyPrefix}.cart-label`,
      type: 'item',
      icon: 'fa fa-shopping-cart',
      url: [`/projects/${project.Id}/presentations/${presentation.Id}/cart`],
      badge: {
        title:
          // We shouldn't set cart count if the presentation status is quote requested or quote created.
          presentation.Status === PresentationStatus.PostShare &&
          cartCount !== 0
            ? cartCount
            : '',
      },
      classes: 'cart-menu',
      hidden:
        presentation.Status !== PresentationStatus.PostShare ||
        cartCount === 0 ||
        presentationExpired,
    },
    userIsCustomer && {
      id: MenuItemId.QuotesMenuItem,
      title: `${keyPrefix}.pending-quote-label`,
      type: 'item',
      icon: 'fa fa-tags',
      url: [`/projects/${project.Id}/presentations/${presentation.Id}/quotes`],
      // `hidden` must be truthy for pending quote menu item if there're no quotes
      // at all or the presentation status is not `Quote Requested`. The presentation
      // status might be `Quote Sent`, which means the quote is NOT pending.
      hidden:
        presentation.Quotes!.length === 0 ||
        presentation.Status !== PresentationStatus.QuoteRequested,
    },
    {
      id: MenuItemId.QuotesMenuItem,
      title: `${keyPrefix}.quotes-label`,
      type: 'item',
      icon: 'fa fa-tags',
      url: [`/projects/${project.Id}/quotes`],
      badge: {
        title: quoteCount !== 0 ? quoteCount : '',
      },
      // `hidden` must be `true` for quotes menu item if presentation status
      // is still post share.
      hidden: quoteCount === 0,
    },
    {
      id: MenuItemId.OrdersMenuItem,
      title: `${keyPrefix}.orders-label`,
      type: 'item',
      icon: 'fa-solid fa-file-invoice',
      url: [`/projects/${project.Id}/orders`],
      badge: {
        title: orderCount !== 0 ? orderCount : '',
      },
      hidden: orderCount === 0,
    },
    userIsCustomer && {
      id: MenuItemId.ProofsMenuItem,
      title: `${keyPrefix}.proofs-label`,
      type: 'item',
      icon: 'fa-solid fa-file-circle-check',
      url: [`/projects/${project.Id}/proofs`],
      badge: {
        title: proofCount !== 0 ? proofCount : '',
      },
      hidden: proofCount === 0,
    },

    {
      id: MenuItemId.InvoicesMenuItem,
      title: `${keyPrefix}.invoices-label`,
      type: 'item',
      icon: 'fa-solid fa-file-invoice-dollar',
      url: [`/projects/${project.Id}/invoices`],
      badge: {
        title: invoiceCount !== 0 ? invoiceCount : '',
      },
      hidden: invoiceCount === 0,
    },
  ].filter(Boolean) as NavigationItem[];
}

export function assembleMobileItems(
  options: NavigationMenuOptions
): MenuItem[] {
  const {
    userIsCustomer,
    cartCount,
    quoteCount,
    orderCount,
    invoiceCount,
    proofCount,
  } = options;

  const project = options.project!;
  const presentation = options.presentation!;
  const presentationExpired = options.presentationExpired;

  return [
    {
      id: MenuItemId.ProductsMenuItem,
      title: `${keyPrefix}.products-label`,
      type: 'item',
      icon: 'fa fa-boxes',
      url: [
        `/projects/${project.Id}/presentations/${presentation.Id}/products`,
      ],
      hidden:
        userIsCustomer && presentation.Status === PresentationStatus.PreShare,
    },
    userIsCustomer && {
      id: MenuItemId.CartMenuItem,
      title: `${keyPrefix}.cart-label`,
      type: 'item',
      icon: 'fa fa-shopping-cart',
      url: [`/projects/${project.Id}/presentations/${presentation.Id}/cart`],
      badge: {
        title:
          // We shouldn't set cart count if the presentation status is quote requested or quote created.
          presentation.Status === PresentationStatus.PostShare &&
          cartCount !== 0
            ? cartCount
            : '',
      },
      classes: 'cart-menu',
      hidden:
        presentation.Status !== PresentationStatus.PostShare ||
        cartCount === 0 ||
        presentationExpired,
    },
    userIsCustomer && {
      id: MenuItemId.QuotesMenuItem,
      title: `${keyPrefix}.pending-quote-label`,
      type: 'item',
      icon: 'fa fa-tags',
      url: [`/projects/${project.Id}/presentations/${presentation.Id}/quotes`],
      // `hidden` must be truthy for pending quote menu item if there're no quotes
      // at all or the presentation status is not `Quote Requested`. The presentation
      // status might be `Quote Sent`, which means the quote is NOT pending.
      hidden:
        presentation.Quotes!.length === 0 ||
        presentation.Status !== PresentationStatus.QuoteRequested,
    },
    {
      id: MenuItemId.QuotesMenuItem,
      title: `${keyPrefix}.quotes-label`,
      type: 'item',
      icon: 'fa fa-tags',
      url: [`/projects/${project.Id}/quotes`],
      badge: {
        title: quoteCount !== 0 ? quoteCount : '',
      },
      hidden: quoteCount === 0,
    },
    {
      id: MenuItemId.OrdersMenuItem,
      title: `${keyPrefix}.orders-label`,
      type: 'item',
      icon: 'fa-solid fa-file-invoice',
      url: [`/projects/${project.Id}/orders`],
      badge: {
        title: orderCount !== 0 ? orderCount : '',
      },
      hidden: orderCount === 0,
    },
    {
      id: 'more',
      title: `${keyPrefix}.more-label`,
      type: 'collapsable',
      icon: 'fa fa-bars',
      component: async () =>
        (
          await import(
            /* webpackChunkName: 'bottom-sheet-menu' */ './bottom-sheet-menu'
          )
        ).CustomerPortalBottomSheetMenuComponent,
      hidden: invoiceCount === 0 && proofCount === 0,
    },
  ].filter(Boolean) as MenuItem[];
}
