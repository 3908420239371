/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { DOCUMENT, ɵgetDOM } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ɵKeyEventsPlugin, type EventManager } from '@angular/platform-browser';

import { getZoneUnPatchedApi } from '@cosmos/zone-less';

import {
  ensureMarkForCheckIsCalledIfTheAngularZoneIsReEntered,
  unwrapEventName,
  unwrapOriginalListener,
} from './utils';

@Injectable()
export class SilentEventPlugin {
  /** Will be set by Angular. */
  manager!: EventManager;

  private readonly _modifier = 'silent';

  private readonly _document = inject(DOCUMENT);

  addEventListener(
    element: HTMLElement,
    eventName: string,
    wrappedWithPreventDefaultListener: Function
  ): Function {
    eventName = unwrapEventName(eventName, this._modifier);

    // The `.silent` plugin should be only considered in the browser runtime. The zone.js
    // doesn't patch `EventTarget` on the Node.js side.
    if (global_isServer) {
      return this.manager.addEventListener(
        element,
        eventName,
        wrappedWithPreventDefaultListener
      );
    }

    const originalListener = unwrapOriginalListener(
      wrappedWithPreventDefaultListener
    );

    ngDevMode &&
      ensureMarkForCheckIsCalledIfTheAngularZoneIsReEntered(originalListener);

    getZoneUnPatchedApi(element, 'addEventListener').call(
      element,
      eventName,
      originalListener
    );

    return () =>
      getZoneUnPatchedApi(element, 'removeEventListener').call(
        element,
        eventName,
        originalListener
      );
  }

  addGlobalEventListener(
    element: string,
    eventName: string,
    wrappedWithPreventDefaultListener: Function
  ): Function {
    return this.addEventListener(
      ɵgetDOM().getGlobalEventTarget(this._document, element),
      eventName,
      wrappedWithPreventDefaultListener
    );
  }

  supports(eventName: string): boolean {
    return (
      // eventName.endsWith x 49,216,564 ops/sec ±1.01% (63 runs sampled)
      // regexp.test(eventName) x 32,703,855 ops/sec ±2.84% (40 runs sampled)
      eventName.endsWith(this._modifier) &&
      ɵKeyEventsPlugin.parseEventName(
        unwrapEventName(eventName, this._modifier)
      ) === null
    );
  }
}
