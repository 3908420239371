import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import {
  CustomerPortalDistributorActions,
  CustomerPortalPresentationActions,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectDetailsQueries,
} from '@customer-portal/projects/data-access/store';

@Injectable({ providedIn: 'root' })
export class CustomerPortalProjectDetailsResolver implements Resolve<void> {
  constructor(private readonly _store: Store) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { projectId } = getParamsObject(route.paramMap, {
      required: ['projectId'],
    })!;

    // Note: do not return the result of this observable since it shouldn't
    // block the UI from loading; this should act as fire & forget; hereby,
    // we're able to show skeleton loaders.
    this._store
      .dispatch(
        new CustomerPortalProjectDetailsActions.GetProjectDetails(projectId)
      )
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        // We first need to get the project details since it has the `PresentationId`.
        const presentationId = this._store.selectSnapshot(
          CustomerPortalProjectDetailsQueries.getPresentationId
        );
        const presentation = this._store.selectSnapshot(
          CustomerPortalPresentationQueries.getPresentation
        );
        const actions: Array<object> = [
          new CustomerPortalDistributorActions.GetDistributor(presentationId),
          new CustomerPortalDistributorActions.GetDesign(presentationId),
        ];
        const settingsParam = route.queryParamMap.get('settings');
        // at this moment we already might have presentation loaded, check this /libs/customer-portal/auth/data-access-customer-portal-access-guard/src/lib/legacy-url-redirect-handler.ts#L90
        // so we don't load it twice
        if (presentation?.Id !== presentationId) {
          actions.push(
            new CustomerPortalPresentationActions.GetPresentation({
              presentationId,
              shouldLoadQuote: true,
              settings: settingsParam,
            })
          );
        } else {
          // otherwise, load the pending quote and apply optional settings
          // (dt): can we postpone quote loading until the user navigates to the quote page?
          actions.push(
            new CustomerPortalPresentationActions.GetPendingQuote(
              presentationId
            )
          );
          if (settingsParam) {
            actions.push(
              new CustomerPortalPresentationActions.ApplyPresentationSettingsParams(
                settingsParam
              )
            );
          }
        }
        this._store.dispatch(actions);
      });
  }
}
